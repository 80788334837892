<template>
  <div class="table-padding">
    <div class="filter-sorting-container">
      <k-search-bar-section
        placeholder="Search By Brand, Retailer, Order..."
        hide-sort-option
        hide-filter-option
        hide-column-edit-option
        @on-search="updateSearch"
      />
      <multiple-filter
        class="ms-2"
        :filter-fields="filterFields"
        @on-apply-filter="onApplyFilter"
      />
      <k-sorting
        class="ms-auto"
        :edit-col-active="editColActive"
        :sort-items="sortFields"
        demo-checkbox-label="Include Demo Orders"
        :self-managed-checkbox-label="`${ showSelfManagedData ? 'Hide':'Show'} Direct Orders`"
        show-without-shipments-checkbox-label="Exclude Shipments"
        show-demo-checkbox
        show-self-managed-checkbox
        show-without-shipments-checkbox
        @update-sort="updateSort"
        @toggle-header-column-edit="onSetOrderListHeaderColumn()"
        @update-sort-direction="updateSortDirection"
        @update-demo-filter="updateDemoFilter"
        @update-self-managed-filter="updateSelfManagedFilter"
        @update-without-shipments-filter="updateWithoutShipmentsFilter"
      />
    </div>
    <div
      v-if="loading"
      class="spinner-body"
    >
      <b-spinner />
    </div>
    <div
      v-else
      class="table-responsive"
    >
      <!-- Table section -->
      <p-table
        :fields="fields"
        :items="items"
      >
        <template #empty>
          <p class="h2 d-flex table-empty-message justify-content-center align-items-center gap-2">
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Filtered Items </span>
          </p>
        </template>
        <template #cell(orderSeqId)="data">
          <b-link
            class="text-decoration-none text-nowrap"
            :to="{ path : `/order-details/${data.item.orderObjId}` }"
          >
            {{ (data.item.orderSeqId || data.item.orderObjId) }}
          </b-link>        </template>
        <template #cell(createdAt)="data">
          {{ formattedDate(data.item.createdAt) }}
        </template>
        <template #cell(expectedAvailabilityDate)="data">
          <span v-if="data.item.expectedAvailabilityDate"> {{ getMonthAndYearFromDate(data.item.expectedAvailabilityDate) }} </span>
        </template>
        <template #cell(paymentTerms)="data">
          <span class="text-nowrap">
            {{ data.item.paymentTerms }}
          </span>
        </template>
        <template #cell(orderStatusDate)="data">
          {{ formattedDate(data.item.orderStatusDate) }}
        </template>
        <template #cell(totalPrice)="data">
          <span class="text-nowrap">
            {{ formatCurrency((data.item.totalPriceAfterDiscount || data.item.totalPrice), data.item.currency) }}
            <span
              v-if="data.item.discountInCredit"
              v-b-tooltip.hover.bottom="`${data.item.discountInCredit} Credit Points were applied to this order`"
              class="credit-point-tooltip"
            >
              CP
            </span>
          </span>
        </template>
      </p-table>
    </div>
    <k-table-pagination
      :total-items="itemsCount"
      @emit-current-page="updateCurrentPage"
      @emit-per-page="updatePerPage"
    />
  </div>
</template>

<script>
import { BIcon, VBTooltip } from 'bootstrap-vue'
import { PTable, KTablePagination, KSearchBarSection } from '@kingpin-global/kingpin-ui'
import { PTablePaginationMixin } from '@/mixins/p-table.mixin'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import {
  SET_COMPONENT,
  SET_COMPONENT_CONTENT,
  SET_FORM_ACTIONS,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { mapState, mapGetters } from 'vuex'
import { FETCH_ORDERS, SET_PAYLOAD } from '@/store/modules/order.module'
import { formattedDate, capitalize } from '@/@core/utils/format'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { getOrderTableFields } from '@/table-fields-constants'
import HeaderColumns from '../filter/HeaderColumns.vue'

const { formatCurrency, getMonthAndYearFromDate } = utils

export default {
  name: 'OrderList',
  components: {
    PTable,
    KTablePagination,
    KSearchBarSection,
    BIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [KSearchAndSortMixin, PTablePaginationMixin],
  data() {
    return {
      isDesc: false,
      formatCurrency,
      fields: getOrderTableFields(),
      headerComponentContent: {
        headerColumns: [],
      },
      formattedDate,
      loaded: false,
      showDemoData: false,
      showSelfManagedData: false,
      showOrdersWithoutShipments: false,
      getMonthAndYearFromDate,
    }
  },
  computed: {
    ...mapState({
      orders: state => (state.orders.orderList ? state.orders.orderList : []),
    }),
    ...mapGetters([
      'getRetailerName',
      'getBrandName',
    ]),
    items() {
      // Updating field data to db name Fields to perform backend sort
      return this.orders.map(item => ({
        orderObjId: item._id,
        orderSeqId: item.orderSeqId,
        createdAt: item.createdAt,
        brandName: item.brandName,
        retailerName: item.retailerName,
        collectionName: item.collectionName,
        status: capitalize(item.status),
        orderStatusDate: item.orderStatusDate,
        totalPrice: item.totalPrice,
        totalQuantity: item.totalQuantity,
        paymentTerms: item.paymentTerms,
        expectedAvailabilityDate: item.expectedAvailabilityDate || '',
        discountInCredit: item.discountInCredit || 0,
        totalPriceAfterDiscount: item.totalPriceAfterDiscount || 0,
        currency: item.currency || '',
      }))
    },
    sortFields() {
      return this.fields.filter(field => !['totalPrice', 'totalQuantity', 'orderStatusDate', 'paymentTerms'].includes(field.key))
    },
    loading() {
      return !(this.items.length || this.loaded)
    },
  },
  async created() {
    await this.initPageContent()
  },
  methods: {
    async initPageContent() {
      this.headerComponentContent.headerColumns = this.fields.slice()
      this.loaded = false
      if (this.orders.length > 0) {
        this.updateFilteredItemAndPaginationParams(this.items)
      }
      const queryParams = {
        page: this.currentPage - 1,
        limit: this.perPage,
        search: this.searchText,
        isDemo: this.showDemoData,
        isSelfManaged: this.showSelfManagedData,
        isOrderWithoutShipments: this.showOrdersWithoutShipments,
      }
      if (this.sortBy) {
        queryParams.sortBy = this.sortBy
        queryParams.asc = !this.isDesc
      }
      if (Object.values(this.filterQuery).length) {
        queryParams.filter = JSON.stringify(this.filterQuery)
      }
      this.$store.commit(SET_PAYLOAD, queryParams)
      this.$store.dispatch(FETCH_ORDERS, queryParams)
        .then(res => {
          this.setFilterFields(res.data?.filter, this.fields)
          this.itemsCount = this.$store.getters.orderCount
          this.updateFilteredItemAndPaginationParams(this.items)
          this.loaded = true
        })
    },
    onSetOrderListHeaderColumn() {
      this.$store.commit(SET_TITLE, 'Edit Columns')
      this.$store.commit(SET_COMPONENT, HeaderColumns)
      this.$store.commit(SET_COMPONENT_CONTENT, this.headerComponentContent)
      this.$store.commit(SET_FORM_ACTIONS, this.editHeaderformActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    editHeaderformActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.updateHeaderColumns()
          break
        case 'cancel':
        default:
          this.toggleSidebar()
      }
    },
    updateHeaderColumns() {
      this.fields = this.headerComponentContent.headerColumns.filter(field => field.value)
      this.toggleSidebar()
    },
    tableColumnUpdateEmits(data) {
      this.items[data.index][data.key] = data.newValue
    },
  },
}
</script>
