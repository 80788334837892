export const PTablePaginationMixin = {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      itemsCount: 0,
      currentPageCount: 0,
      filteredItems: [],
      isDesc: false,
    }
  },
  methods: {
    getCurrentPageCount() {
      const totalCount = this.itemsCount
      const pageCount = totalCount / this.perPage
      if (this.currentPage <= pageCount) {
        this.currentPageCount = this.perPage
      }
      else {
        this.currentPageCount = Math.ceil(totalCount % this.perPage)
      }
      this.$emit('update-total-items', this.currentPageCount, this.itemsCount)
    },
    updateFilteredItemAndPaginationParams(items) {
      this.filteredItems = items
      this.getCurrentPageCount()
    },
    async updateCurrentPage(value) {
      this.currentPage = value
      this.getCurrentPageCount()
      await this.initPageContent()
    },
    async updatePerPage(value) {
      this.perPage = value
      this.getCurrentPageCount()
      await this.initPageContent()
    },
    async updateSortDirection(value) {
      this.isDesc = value
      await this.initPageContent()
    },
  },
  emits: ['update-total-items'],
}
